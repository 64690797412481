<template>
  <v-container class="backup-container" fluid>
    <v-row class="system-inner-row">
      <v-col cols="12" class="pa-5 synapsa-tabs">
        <v-card class="theme-provider">
          <div class="btn-group">
            <v-btn
              v-for="tab in tabs"
              :key="tab.id"
              :text="tab.id !== activeTab"
              :color="tab.id === activeTab ? 'primary' : ''"
              :outlined="tab.id === activeTab"
              small
              class="text-capitalize synapsa-tab"
              :class="tab.id === activeTab ? 'elevation-1' : ''"
              @click="to(tab)"
            >
              {{ $t(tab.name) }}
            </v-btn>
          </div>
        </v-card>
        <div
          v-if="tabs[0].visible"
          class="backup-tab backup-configuration-tab d-flex align-start"
        >
          <ldap-component />

        </div>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import permission from "@/directive/permission/permission.js";
import LdapComponent from "@/views/system/login/ldap.vue";
export default {
  components: {
    LdapComponent,
  },
  directives: { permission},
  data() {
    return {
      permissions: localStorage.getItem("permissions").split(","),
      activeTab: "ldap",
      tabs: [
        {
          id: "ldap",
          name: "system.loginTab.ldap",
          route: "backup",
          visible: true,
        },
      ],
      checkbox: true,
    };
  },
  methods: {
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
  },
};
</script>
<style lang="scss" scoped>
.system-h4 {
  font-size: 14px !important;
}
.theme-provider {
  padding: 0;
  box-shadow: none !important;
  margin: 0;
  background: none !important;
  border-radius: 15px;
}
.btn-group {
  width: 100%;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
}

.theme-provider.theme--dark ::v-deep {
  .btn-group {
    background: hsla(0, 0%, 100%, 0.08);
  }
}
.backup-checkbox {
  width: 100%;
}
.row + .row {
  margin-top: 0px;
}
.system-inner-row {
  margin-top: 0px !important;
}
.synapsa-tabs {
  margin: 0;
  border-radius: 15px !important;
}
.backup-container {
  font-family: RobotoCondensedRegular, sans-serif !important;
  padding-top: 0;
  margin-top: -4px;
}
.backup-tab-item {
  /* padding: 20px; */
  padding-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.file-input {
  width: 100%;
  border-radius: 15px;
}
.backup-btn {
  border-radius: 15px;
  transition: none;
}

.backup-btn:hover {
  color: white !important;
}

h4 {
  font-family: UbuntuBold, sans-serif;
  font-size: 18px;
}
.v-input ::v-deep {
  .v-input__slot {
    width: calc(100% - 10px) !important;
    border-radius: 12px !important;
  }
}

.v-input__slot {
  width: calc(100% - 10px) !important;
  border-radius: 12px !important;
}

.toggle ::v-deep {
  margin: 35px 0 55px 0;
  min-width: 100%;
  .v-label {
    min-width: 110% !important;
  }
}
</style>
