<template>
  <v-container class="backup-containers" fluid>
    <div class="toggle">
      <v-switch
        v-if="!checkActive"
        :disabled="checkActive || data?.host === null"
        v-model="active"
        :input-value="false"
        :true-value="true"
        :false-value="false"
        :label="loading ? '' : active ? 'enabled' : 'disabled'"
        hide-details
        inset
        :class="[active ? 'v-switch-on' : 'v-switch-off']"
        class="target-background v-switch-off"
        @change="setLdap"
      />
      <v-icon v-else color="primary">fas fa-spinner fa-spin</v-icon>
    </div>
    <ldap-table :data="data" :loading="loading" />
  </v-container>
</template>
<script>

import { Errors } from "@/utils/error";
import permission from "@/directive/permission/permission.js";
import { getLdap, getLdapStatus, setLdapStatus } from "@/api/system/login";
import ldapTable from "@/views/system/login/ldapTable.vue";
export default {
  components: {
    ldapTable,
  },
  directives: { permission },
  data() {
    return {
      permissions: localStorage.getItem("permissions").split(","),
      data: null,
      active: false,
      loading: false,
      checkActive: true,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "systemLdap") {
          this.loadData();
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadLdapStatus();
    this.loadData();
  },
  methods: {
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
    loadData() {
      this.loading = true;
      getLdap()
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    setLdap() {
      this.checkActive = true
      setLdapStatus({ active: this.active })
        .then(() => {
          this.$toast.open({
            message: 'Status was saved',
            type: 'success',
            position: "top-right",
            duration: 2000,
          });
          this.checkActive = false
        })
        .catch((e) => {
          Errors.show(e);
          this.active = false;
          this.$toast.open({
            message: e.message,
            type: 'error',
            position: "top-right",
            duration: 2000,
          });
          this.checkActive = false
        });
    },
    loadLdapStatus() {
      this.checkActive = true
      getLdapStatus()
        .then((response) => {
          this.active = response.status;
          this.checkActive = false
        })
        .catch((e) => {
          Errors.show(e);
          this.active = false;
          this.checkActive = false
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.system-h4 {
  font-size: 14px !important;
}
.theme-provider {
  padding: 0;
  box-shadow: none !important;
  margin: 0;
  background: none !important;
  border-radius: 15px;
}
.btn-group {
  width: 100%;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
}

.theme-provider.theme--dark ::v-deep {
  .btn-group {
    background: hsla(0, 0%, 100%, 0.08);
  }
}
.backup-checkbox {
  width: 100%;
}
.row + .row {
  margin-top: 0px;
}
.system-inner-row {
  margin-top: 0px !important;
}
.synapsa-tabs {
  margin: 0;
  border-radius: 15px !important;
}
.backup-container {
  font-family: RobotoCondensedRegular, sans-serif !important;
  padding-top: 0;
  margin-top: -4px;
}
.backup-tab-item {
  /* padding: 20px; */
  padding-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.file-input {
  width: 100%;
  border-radius: 15px;
}
.backup-btn {
  border-radius: 15px;
  transition: none;
}

.backup-btn:hover {
  color: white !important;
}

h4 {
  font-family: UbuntuBold, sans-serif;
  font-size: 18px;
}
.v-input ::v-deep {
  .v-input__slot {
    width: calc(100% - 10px) !important;
    border-radius: 12px !important;
  }
}

.v-input__slot {
  width: calc(100% - 10px) !important;
  border-radius: 12px !important;
}

.toggle ::v-deep {
  margin: 35px 0 55px 0;
  min-width: 100%;
  .v-label {
    min-width: 110% !important;
  }
}
</style>

